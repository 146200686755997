<template>
	<v-container fluid>
		<div class="d-flex flex-wrap py-2">
			<v-btn class="ma-1" color="primary" small to="/estudios/express">
				<v-icon left>mdi-calculator</v-icon>Estudio express
			</v-btn>
			<v-btn class="ma-1" color="primary" small to="/estudios/nuevo">
				<v-icon left>mdi-plus</v-icon>Enviar Estudio
			</v-btn>
			<v-btn class="ma-1" color="primary" small @click="getEstudios">
				<v-icon left>mdi-reload</v-icon>Recargar
			</v-btn>
		</div>
		<v-card>
			<v-data-table
				:headers="estudiosHeaders"
				:items="estudiosFiltrados"
				:loading="loading"
				:single-select="false"
				item-key="idEstudio"
				checkbox-color="secondary"
				show-select
				v-model="selected"
				:calculate-widths="true"
				class="fixed-checkbox"
				:footer-props="{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [10, 25, 50],
				}"
			>
				<template v-slot:body.prepend="{ headers }">
					<table-filters :headers="headers" :items="estudiosFiltrados" v-model="inlineFilters"></table-filters>
				</template>
				<template v-slot:item.estado_actual="{ item }">
					<status-chip :value="item.estado_actual" />
				</template>
				<template v-slot:item.nombreTipo="{ item }">
					<v-chip label outlined :color="item.colorTipo" >
						<v-icon left>{{ item.iconTipo }}</v-icon>
						{{ item.nombreTipo }}
					</v-chip>
				</template>
				<template v-slot:item.enviadoEn="{ item }">
					<span v-text="parseDate(item.enviadoEn, false, true, true)" />
				</template>

				<template v-slot:item.acciones="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								icon
								color="secondary"
								target="_blank"
								:href="`https://www.google.com/maps/search/?api=1&query=${item.latitud}%2C${item.longitud}`"
								:disabled="!item.latitud || !item.longitud"
							>
								<v-icon>mdi-google-maps</v-icon>
							</v-btn>
						</template>
						Abrir en Google Maps
					</v-tooltip>

					<v-btn
            color="primary"
            :to="`/estudios/nuevo?idEstudio=${item.idEstudio}`"
            outlined
            class="rounded-pill"
            small
          >
            <v-icon left>mdi-pencil</v-icon>
            Editar
          </v-btn>

				</template>
			</v-data-table>
		</v-card>
	</v-container>
</template>

<script>
import { perColumnFilter, parseDate } from "@/utils/index.js";

export default {
	components: {
		TableFilters: () => import("@/components/TableFilters.vue"),
		StatusChip: () => import("@/components/StatusChip.vue"),
		DragAndDrop: () => import("@/components/DragAndDrop.vue"),
	},
	mounted() {
		this.getEstudios();
	},
	computed: {
		estudiosFiltrados() {
			return this.estudios;
		},
	},
	methods: {
		async getEstudios() {
			this.loading = true;
			const { data: estudios } = await axios({
				method: "GET",
				url: `${process.env.VUE_APP_API_URL}/estudios`,
				params: {
					borradores: true,
				},
			});
			this.estudios = estudios

			this.loading = false;
		},
		parseDate,
	},
	data() {
		return {
			inlineFilters: {},
			estudiosHeaders: [
				{ text: "Estado", value: "estado_actual", dataType: "select" },
				{ text: "Estudio", value: "idEstudio", align: "end" },
				{ text: "Acciones", value: "acciones", sortable: false, filterable: false, align: "center", },
				{ text: "Tipo", value: "nombreTipo" },
				{ text: "Comercial", value: "comercial", cellClass: 'font-weight-bold pa-2 text-no-wrap' },
				{ text: "SubComercial", value: "subComercial" },
				{ text: "Última modificación", value: "enviadoEn", dataType: "date" },
				{ text: "CUPS", value: "cups" },
				// { text: "Latitud", value: "lat" },
				// { text: "Longitud", value: "lon" },
				// { text: "Inclinación", value: "inclinacion" },
				// { text: "Azimut", value: "azimut" },
			].map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-2 text-no-wrap",
					filter: (value) =>
						perColumnFilter(
							value,
							this.inlineFilters[header.value],
							header.dataType
						),
					dataType: "text",
					...header,
				};
			}),
			estudios: [],
			selected: [],
			loading: false,
		};
	},
};
</script>

<style scoped>
.v-data-table /deep/ [role="columnheader"] {
	background: #f1f1f1 !important;
}
</style>
.v-data-table /deep/ [role="columnheader"] {
	background: #f1f1f1 !important;
}
